import React, { useState } from "react";
import { Container, Row, Col } from "shards-react";

const FtmList = (props) =>{
    return(
        <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
            <div className="card card-small">
                <div className="card-header border-bottom">
                    <h6 className="m-0">FTM List</h6>
                </div>
                <div className="card-body p-0">
                    <ul className="list-group">
                        {props.ftms.map((ftm) =>
                            <li className="list-group-item" style={{cursor: "pointer"}} onClick={() => props.setSelectedFtm(ftm)} key={ftm.ftm_id}>
                                <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    width="16" 
                                    height="16" 
                                    fill="currentColor" 
                                    className="bi bi-circle-fill" 
                                    viewBox="0 0 16 16" 
                                    style={{color: ftm.ftms_schedule_color,marginRight: '20px'}}
                                >
                                    <circle cx="8" cy="8" r="8"></circle>
                                </svg>
                                {ftm.engineername}
                            </li>
                        )}
                    </ul>
                </div>
                <div className="card-footer border-top">
                    <div className="row">
                        <div className="col text-right view-report">
                            <button 
                                type="button" 
                                className="mb-2 btn btn-sm btn-primary mr-1" 
                                onClick={() => props.setSelectedFtm({})}
                            >Reset Map &rarr;</button>
                        </div>
                    </div>
                </div>
            </div>
          </div>
    );
}

export default FtmList;