import React,{useState,useEffect} from "react";
import { useLoadScript,GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";

import Loader from "./common/Loader";
import config from "../config";
import { io } from "socket.io-client";

let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MThlMGM5N2I3MTU1MWU4NjhjZGFlYTIiLCJjb21wYW5pZXNJZCI6IjYxNWQzMmU3MTBhMDIzMjlkMTIxNzBjYiIsImZpcnN0TmFtZSI6Ik51d2FuIiwibGFzdE5hbWUiOiJSYW5hd2FrYSIsInR5cGUiOiJmbG9ib3QiLCJpYXQiOjE2NDU2MjU4NzZ9.2eK02UUkYEh767v2jeJJj2qiXXyMq7r3t2yg3jodL-Y";

const socket = io.connect(config.socket_url, {
  query: {token},
});

socket.on('currentStatus', function (data){
  console.log(data)
});

const Map = (props) => {
  const [activeMarker, setActiveMarker] = useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_API_KEY // Add your API key
  });

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend({lat: parseFloat(props.ftm.lat), lng: parseFloat(props.ftm.lon)});
    map.fitBounds(bounds);
  };

  return(
    <>
        {isLoaded ?
            <GoogleMap
                zoom={8} 
                onLoad={handleOnLoad}
                onClick={() => setActiveMarker(null)}
                mapContainerStyle={{ height : "800px", width : "100%" }}
            >
                <Marker
                    key={props.ftm.ftm_id}
                    position={{lat: parseFloat(props.ftm.lat), lng: parseFloat(props.ftm.lon)}}
                    onClick={() => handleActiveMarker(props.ftm.ftm_id)}
                    icon={config.map_icon}
                >
                    {activeMarker === props.ftm.ftm_id ? (
                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                            <div>
                                <div id="content">
                                <div id="siteNotice"></div>
                                <h5 id="firstHeading" className="firstHeading">{props.ftm.engineername}</h5>
                                {/* <div id="bodyContent">
                                    <b>Device id: </b>{device_id}<br/>
                                    <b>Speed: </b>{speed} Kmh<br/>
                                    <b>Date & time: </b>{datetime}<br/>
                                </div> */}
                                </div>
                            </div>
                        </InfoWindow>
                    ) : null}
                </Marker>
            </GoogleMap>
        : null}
    </>
    
  );
}

export default Map;
