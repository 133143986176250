import "bootstrap/dist/css/bootstrap.min.css";
import "./css/styles.min.css";

import FullMap from './components/FullMap'
import MapOnly from './components/MapOnly'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

const App = () => {
  return (
    <Router>
      <Switch>
          <Route exact path='/maponly' component={MapOnly} />
          <Route>
            <FullMap/>
          </Route>
      </Switch>
    </Router>
  );
}

export default App;
