import React, { useState } from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Map from "./Map"
import FtmMap from "./FtmMap"
import FtmList from "./FtmList"

const FullMap = ({ history,props }) =>{
  const [ftms, setFtms] = useState([]);
  const [selectedFtm, setSelectedFtm] = useState([]);

  return(
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        {/* <PageTitle title="My Flobot" subtitle="www.freshmilksoftware.com" md="12" className="ml-sm-auto mr-sm-auto" /> */}
      </Row>
      <Row>
        <Col lg="3" >
          <FtmList
            ftms = {ftms}
            history = {history}
            setSelectedFtm = {setSelectedFtm}
          />
        </Col>

        <Col lg="9" >
          {Object.keys(selectedFtm).length > 0 ? 
            <FtmMap
              setFtms = {setFtms}
              selectedFtm = {selectedFtm}
              ftm = {selectedFtm}
            />
          : 
            <Map
              setFtms = {setFtms}
              selectedFtm = {selectedFtm}
              mapHeight = "800px"
            />
          }
          
        </Col>
      </Row>
    </Container>
  );
}

export default FullMap;
