import React, { useState } from "react";
import { Container, Row, Col } from "shards-react";

import Map from "./Map"

const MapOnly = ({ history,props }) =>{
  const [ftms, setFtms] = useState([]);

  return(
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        {/* <PageTitle title="My Flobot" subtitle="www.freshmilksoftware.com" md="12" className="ml-sm-auto mr-sm-auto" /> */}
      </Row>
      <Row>
        <Col lg="12" >
            <Map
              setFtms = {setFtms}
              mapHeight = "500px"
            />
        </Col>
      </Row>
    </Container>
  );
}

export default MapOnly;
