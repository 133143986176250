import React,{useState,useEffect} from "react";
import { useLoadScript,GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";

import Loader from "./common/Loader";
import config from "../config";
import { io } from "socket.io-client";

let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MThlMGM5N2I3MTU1MWU4NjhjZGFlYTIiLCJjb21wYW5pZXNJZCI6IjYxNWQzMmU3MTBhMDIzMjlkMTIxNzBjYiIsImZpcnN0TmFtZSI6Ik51d2FuIiwibGFzdE5hbWUiOiJSYW5hd2FrYSIsInR5cGUiOiJmbG9ib3QiLCJpYXQiOjE2NDU2MjU4NzZ9.2eK02UUkYEh767v2jeJJj2qiXXyMq7r3t2yg3jodL-Y";

const socket = io.connect(config.socket_url, {
  query: {token},
});

socket.on('currentStatus', function (data){
  console.log(data)
});

const Map = (props) => {
  const [markers, setMarkers] = useState([]);
  const [activeMarker, setActiveMarker] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [gps_location_count, setGpsLocationCount] = useState(0);
  const [flobot_location_count, setFlobotLocationCount] = useState(0);
  const [id_count, setIdCount] = useState(0);

  let map_width = window.innerWidth - (window.innerWidth === 1920 ? 420 : 350);
  if(window.innerWidth < 400){
    map_width = ""
  }

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_API_KEY // Add your API key
  });

  useEffect(() => {
    getDbLocations();
  },[]);

  useEffect(() => {
    socket.on('location_list', async function(list) {
        const index = markers.findIndex(
          (element) => element.ftm_id === parseInt(list.flobot_ftm_id)
        );

        if(index !== -1) {
          markers[index]["position"] = { lat: parseFloat(list.lat), lng: parseFloat(list.lon)};
        }else{
          let new_id_count = id_count+1;
          setIdCount(new_id_count);

          let obj = {
            id: new_id_count,
            ftm_id:parseInt(list.flobot_ftm_id),
            device_icon:'https://img.icons8.com/ios-glyphs/30/000000/gps-receiving.png',
            datetime:list.datetime,
            name: list.firstName+" "+list.lastName,
            position: { lat: parseFloat(list.lat), lng: parseFloat(list.lon)}
          }
          markers.push(obj);
        }
        let new_gps_location_count = gps_location_count+1;
        setGpsLocationCount(new_gps_location_count);
    });
  },[gps_location_count]);

  useEffect(() => {
    socket.on('location_flobot_list', async function(list) {
      console.log(list);
        const index = markers.findIndex(
          (element) => element.ftm_id === parseInt(list.ftmid)
        );

        if(index !== -1) {
          markers[index]["position"] = { lat: parseFloat(list.lat), lng: parseFloat(list.lon)};
        }else{
          let new_id_count = id_count+1;
          setIdCount(new_id_count);

          let device_icon = "";
          if(list.type === "ios"){
            device_icon = 'https://img.icons8.com/ios-glyphs/30/000000/mac-os.png';
          }else{
            if(list.type === "android"){
              device_icon = 'https://img.icons8.com/ios-glyphs/30/000000/android-os.png';
            }else{
              device_icon = 'https://img.icons8.com/ios-glyphs/30/000000/gps-receiving.png';
            }
          }

          let obj = {
            id: new_id_count,
            ftm_id: parseInt(list.ftmid),
            datetime: list.datetime,
            device_icon:device_icon,
            name: list.firstName+" "+list.lastName,
            position: { lat: parseFloat(list.lat), lng: parseFloat(list.lon)}
          }
          
          markers.push(obj);
        }
        let new_flobot_location_count = flobot_location_count+1;
        setFlobotLocationCount(new_flobot_location_count);
    });
  },[flobot_location_count]);

  const getDbLocations = async () => {
    setIsLoading(true);
    let response = await loadDbLocations();
    let locations = response["data"];
    props.setFtms(locations);

    let new_id_count = 0;
    for (const location of locations) {
      new_id_count++;
      setIdCount(new_id_count);
      
      let device_icon = "";
      if(location.device === "IOS"){
        device_icon = 'https://img.icons8.com/ios-glyphs/30/000000/mac-os.png';
      }else{
        if(location.device === "ANDROID"){
          device_icon = 'https://img.icons8.com/ios-glyphs/30/000000/android-os.png';
        }else{
          device_icon = 'https://img.icons8.com/ios-glyphs/30/000000/gps-receiving.png';
        }
      }

      let obj = {
        id: new_id_count,
        device: location.device,
        datetime: location.datetime,
        device_icon: device_icon,
        ftm_id: location.ftm_id,
        name: location.engineername,
        position: { lat: location.lat, lng: location.lon}
      }
      
      markers.push(obj);
    }

    setIsLoading(false);
  }

  const loadDbLocations = () => {
    return new Promise(async (resolve, reject) => {
      fetch(config.api_url+'flobot-details', {
        method: 'GET', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
    });
  }

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  return(
    <>
    {!isLoading ? (
      <>
        {isLoaded ?
          <GoogleMap
              onLoad={handleOnLoad}
              onClick={() => setActiveMarker(null)}
              mapContainerStyle={{ height : props.mapHeight, width : "100%" }}
            >
              {markers.map(({ id, device, name, position, datetime, device_icon }) => (
                <Marker
                  key={id}
                  position={position}
                  onClick={() => handleActiveMarker(id)}
                  icon={config.map_icon}
                >
                  {activeMarker === id ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                      <div>
                        <div id="content" style={{textAlign:"center"}}>
                          <div id="siteNotice"></div>
                          <h5 id="firstHeading" className="firstHeading">{name}</h5>
                          <div id="bodyContent" >
                            <b>Device: </b>{device}<br/>
                            <b>Last seen: </b>{datetime}<br/>
                            <img src={device_icon}/>
                          </div>
                        </div>
                      </div>
                    </InfoWindow>
                  ) : null}
                </Marker>
              ))}
            </GoogleMap>
        : null}
      </>
    ):(
      <Loader/>
    )}
    </>
  );
}

export default Map;
